import { onMount } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import { SITE_TITLE } from "~/consts";
import { getUser } from "~/db/session";
import { setPageTitle } from "~/root";
import { setTrialLeft } from "~/store/trial";
import {
  setJwtToken,
  setUserAvatar,
  setUserId,
  setUserRole,
} from "~/store/user-form-role";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import LoginComponent from "~/components/global/Login";
import { notify } from "~/components/global/Notification";
import messages from "~/helpers/message";
import { setPopupQueue } from "~/store/global.store";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);

export function routeData() {
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    if (userDetails) {
      if (userDetails.data.role.role_name == "admin") {
        throw redirect("/form/list");
      } else if (userDetails.data.role.role_name == "super_admin") {
        throw redirect("/admin/dashboard");
      } else {
        if (userDetails.data.has_set_password) {
          return redirect("/form/list");
        } else {
          return redirect("/change-password");
        }
      }
    }
    return {};
  });
}

export default function Login() {
  setPageTitle(`${SITE_TITLE} - Login`);
  const data = useRouteData<typeof routeData>();
  data();

  onMount(() => {
    if (
      localStorage.getItem("logged_in_at") &&
      localStorage.getItem("new_logged_in_at") &&
      localStorage.getItem("logged_in_at") ==
        localStorage.getItem("new_logged_in_at")
    ) {
      notify({
        isNotified: true,
        message:
          localStorage.getItem("subscription_cancel") == "true"
            ? messages.warn.subscriptionCancelLogout
            : messages.warn.autologout,
        theme: "warm",
        placement: "top",
        timeout: 5000,
      });
    } else if (
      localStorage.getItem("logged_in_at") &&
      localStorage.getItem("new_logged_in_at") &&
      localStorage.getItem("logged_in_at") !=
        localStorage.getItem("new_logged_in_at")
    ) {
      notify({
        isNotified: true,
        message: messages.warn.otherSessionlogout,
        theme: "warm",
        placement: "top",
        timeout: 5000,
      });
    }
    localStorage.removeItem("logged_in_at");
    localStorage.removeItem("new_logged_in_at");
    localStorage.removeItem("subscription_cancel");
    setUserRole("");
    setUserId("");
    setJwtToken("");
    setUserAvatar("");
    setTrialLeft(0);
    setPopupQueue([]);
  });

  return (
    <main>
      <LoginComponent></LoginComponent>
    </main>
  );
}
